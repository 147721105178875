import { VideoCameraOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { useState } from "react";
import SimpleBeam from "./simpleBeam/SimpleBeam";
import "./App.css";
import logo from "../images/logo.png";

const { Header, Content, Footer, Sider } = Layout;

const items = [
    {
        key: "simple-beam",
        icon: <VideoCameraOutlined />,
        label: "Simple Beam",
        content: <SimpleBeam />
    }
];

const defaultKey = items[0].key;

const App = () => {
    const [activeKey] = useState(defaultKey);

    const activeItem = items.find((item) => item.key === activeKey);

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Sider breakpoint="lg" collapsedWidth="0">
                <div className="app-logo">
                    <img src={logo} alt="" />
                </div>
                <Menu theme="dark" mode="inline" defaultSelectedKeys={[defaultKey]} items={items} />
            </Sider>
            <Layout>
                <Header className="app-layout-sub-header" style={{ background: "#fff" }} />
                <Content
                    style={{
                        margin: "24px 16px 0"
                    }}
                >
                    <div className="app-layout-background">{activeItem.content}</div>
                </Content>
                <Footer
                    style={{
                        textAlign: "center"
                    }}
                >
                    Made with ♥ in SG ©2022
                </Footer>
            </Layout>
        </Layout>
    );
};

export default App;
