import React from "react";
import { CalculationForm } from "../calculationForm";
import simpleBeamImage from "../../images/simple-beam.jpg";

const SimpleBeam = () => {
    const defaultFormulas = [`R_{1} = `, `R_{2} = `, `V_{x} = `, `M_{x} = `];

    const fields = [
        { label: "a", name: "a" },
        { label: "b", name: "b" },
        { label: "c", name: "c" },
        { label: "l", name: "l" },
        { label: "w", name: "w" },
        { label: "x", name: "x" }
    ];

    const calculate = ({ a, b, c, l, w, x }) => {
        const R1 = ((w * b) / (2 * l)) * (2 * c + b);
        const V1 = R1;
        const R2 = ((w * b) / (2 * l)) * (2 * a + b);
        const V2 = R2;

        let Vx;
        if (x <= a) {
            Vx = V1;
        } else if (x > a && x < a + b) {
            Vx = R1 - w * (x - a);
        } else {
            Vx = V2;
        }

        let Mx;
        if (x <= a) {
            Mx = R1 * x;
        } else if (x > a && x < a + b) {
            Mx = R1 * x - (w / 2) * Math.sqrt(x - a);
        } else {
            Mx = R2 * (l - x);
        }

        return [`R_{1} = ${R1}`, `R_{2} = ${R2}`, `V_{x} = ${Vx}`, `M_{x} = ${Mx}`];
    };

    return (
        <CalculationForm
            name="simple-beam"
            title="Simple Beam"
            subTitle="Uniform Load Partially Distributed"
            image={simpleBeamImage}
            fields={fields}
            defaultFormulas={defaultFormulas}
            calculate={calculate}
        />
    );
};

export default SimpleBeam;
