import { Button, Form, Input, Typography, Row, Col } from "antd";
import React, { useState } from "react";
import MathJax from "react-mathjax";
import "./CalculationForm.css";

const { Title } = Typography;

const SimpleBeam = ({ name, title, subTitle, image, defaultFormulas, fields, calculate }) => {
    const [formulas, setFormulas] = useState(defaultFormulas);

    const onFinish = (values) => {
        setFormulas(calculate(values));
    };

    const formItems = fields.map(({ label, name }) => (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: true
                }
            ]}
        >
            <Input type="number" />
        </Form.Item>
    ));

    return (
        <Form
            name={name}
            labelCol={{
                span: 8
            }}
            wrapperCol={{
                span: 16
            }}
            onFinish={onFinish}
            onFinishFailed={(err) => console.log("Error:", err)}
            autoComplete="off"
        >
            <div style={{ textAlign: "center" }}>
                <Title level={3} style={{ margin: 0 }}>
                    {title}
                </Title>
                <Title
                    level={5}
                    style={{ marginTop: "8px", marginBottom: "32px" }}
                    type="secondary"
                >
                    {subTitle}
                </Title>
            </div>

            <Row>
                <Col xs={24} sm={12} md={{ span: 8, offset: 2 }}>
                    <div className="calculation-image">
                        <img className="calculation-image__img" src={image} alt="" />
                    </div>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <div>
                        {formItems}

                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Calculate
                            </Button>
                        </Form.Item>
                    </div>

                    <div className="calculation-result">
                        <MathJax.Provider>
                            <Row>
                                {formulas.map((formula, i) => (
                                    <Col key={i} span={12}>
                                        <MathJax.Node formula={formula} />
                                    </Col>
                                ))}
                            </Row>
                        </MathJax.Provider>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};

export default SimpleBeam;
